<template>
<CContainer class="login-container d-flex flex-column content-center min-vw-100 min-vh-100 mx-0">
    <div class="brand-img-container text-center d-block" style="margin-top:5%">
        <img class="brand-img" :src="logo" alt="brand"/>
    </div>
    <CRow>
        <CCol class="p-3">
            <CCardGroup>
                <CCard class="login-form-container text-center">
                    <CCardBody>
                        <CForm @submit.prevent @submit="doLogin" autocomplete="off">
                            <h3>Selamat Datang</h3>
                            <p class="text-muted">Platform Kolaborasi dan Smart Office</p>
                            <div :class="usernameFilled? 'd-none':''" class="help-block text-danger text-left small font-italic">Username field is required.</div>
                            <CInput v-model="username" placeholder="Username">
                                <template #prepend-content><CIcon name="cil-user"/></template>
                            </CInput>
                            <div :class="passwordFilled? 'd-none':''" class="help-block text-danger text-left small font-italic">Password field is required.</div>
                            <CInput v-model="password" placeholder="Password" type="password">
                                <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                            </CInput>
                            <div class="d-flex" style="margin-bottom:15px">
                                <CInputCheckbox :inline="true" class="flex-grow-0 p-0" style="width:15px"/>
                                <div class="text-left flex-grow-1 p-0">
                                    Ingat Saya
                                </div>
                            </div>
                            <CRow>
                                <CCol col="6" class="text-left">
                                    <CButton type="submit" color="danger" class="px-4 text-white">Masuk</CButton>
                                </CCol>
                                <CCol col="6" class="text-right">
                                    <CLink @click="redir('/forgot_password')" color="link" class="px-0" style="line-height:2.2">Lupa Kata Sandi?</CLink>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCardGroup>
        </CCol>
    </CRow>
    <CRow>
        <CCol col="12">
            <table style="color:white">
                <tr>
                    <td>Tata Naskah Digital</td>
                </tr>
                <tr>
                    <td>&#169; Kementerian Pertahanan Republik Indonesia</td>
                </tr>
                <tr>
                    <td>Pusat Panggilan: 0812-8595-1569</td>
                </tr>  
                <tr>
                    <td>Aplikasi ini didukung oleh <a href="https://bsre.bssn.go.id/" target="_blank">Balai Sertifikasi Elektronik </a></td>
                </tr>
                <tr>
                    <td><img class="bsre_logo" :src="bsreLogo" alt="brand"/></td>
                </tr>
                

            </table>
        </CCol>
    </CRow>
    
    <CModal :show.sync="showTimerPopUp" :closeOnBackdrop="false">
        <template #header>
            <h5 class="modal-title">Username anda diblokir</h5>
        </template>
        <div class="p-1">
            Harap Menunggu Selama {{timerCountInString}}
        </div>
        
        <template #footer>
            <CButton @click="closePopupModal()" class="btn-sm">Tutup</CButton>
        </template>
    </CModal>
</CContainer>
</template>

<script>
const logo = require('@/assets/img/logo.png')
const bsreLogo = require('@/assets/img/bsre_logo.png')
import {
    createNamespacedHelpers,
    mapState,
    mapGetters
} from 'vuex'

export default {
    name: "Login",
    data() {
        return {
            logo:logo,
            bsreLogo: bsreLogo,
            username: '',
            usernameFilled: true,
            password: '',
            passwordFilled: true,
            process: false,
            showTimerPopUp: false,
            timerCount: null,
            minuteCount: null,
            secondCount: null,
        }
    }, 
    watch: {
        timerCount: {
            handler(value) {

                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if(value == 0 ) {
                    this.showTimerPopUp = false
                }

            },
            immediate: true, // This ensures the watcher is triggered upon creation
        }
        

    },
    computed: {
        timerCountInString: function () {
            return this.calculatedTimeString()
        },
    },
    methods: {
        calculatedTimeString() {
 
            let seconds = this.timerCount % 60
            let minutes = parseInt(this.timerCount / 60, 10) % 60

            let timeLeft = ''
            if(minutes != 0) {
                timeLeft += minutes + " Menit "
            }
            timeLeft += seconds + " Detik"

            return timeLeft
        },

        doLogin () {
            this.usernameFilled = true
            this.passwordFilled = true
            if(this.username == ''){
                this.$nextTick(() => this.usernameFilled = false)
            }else if(this.password == ''){
                this.$nextTick(() => this.passwordFilled = false)
            }else if(this.username != '' && this.password != '' ){
                this.process = true
                setTimeout( ()=>{    
                    this.$store.dispatch('auth/login', {username:this.username, password:this.password}).then( (res) => {
                       
                        if(res == false) {
                            this.$toasted.error('Gagal login, terjadi kesalahan, <br> Periksa kembali User dan Password anda').goAway(3000)
                    
                        }
                        else if(res.data.is_blocked) {
                            this.showTimerPopUp = true
                            this.timerCount = res.data.remaining_second_time_block
                        }
                        else if(res.data.is_blocked == false)
                        {

                            let usernameExist = localStorage.getItem('username')
                            if(usernameExist != null) localStorage.removeItem('username')
                            localStorage.setItem('username', this.username)

                            this.$toasted.success('Selamat datang ' + this.username).goAway(3000)
                            this.redir('/beranda')
                        } else {
                            this.$toasted.error('Gagal login, terjadi kesalahan').goAway(3000)
                        }

                        this.process = false
                    })
                },500)
            }
        },
        loadRequiredStateData () {
            this.$store.dispatch({
                type: 'auth/checkLogin'
            }).then((res) => {
                this.$toasted.success('Selamat datang ' + this.username).goAway(3000)
                this.redir('/beranda')
            })
        },
        redir (message) {
			if(this.$route.path != message){
                this.$router.push(message, () => this.$router.go(0))
				// /this.$router.push({path: message, force: true})
			}
		},
        closePopupModal() {
            this.showTimerPopUp = false
        }
    }
}
</script>

<style scoped>
.container{
    padding: 20px;
}
.brand-img{
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}
.bsre_logo {
    width: 100px;
    height: auto;
    margin-top: 10px;
}
.login-form-container{
    width: 400px!important;
}
a {
    color:white;
}
</style>